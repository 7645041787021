import React, { Component } from "react";
import MyContext from "../context/myContext";
import { logIn, changeLang } from "../../reducers/actions/index";
import Translation from "../../components/translation/translation";
import "./profile-page.scss";
import "../../styles/shared.scss";
import Checkbox from "@material-ui/core/Checkbox";
import MTCaptcha from "../../components/mtcaptcha/mtcaptcha";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import MaskedInput from "react-text-mask";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CircularProgress from "@material-ui/core/CircularProgress";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import { navigate } from "gatsby";
import { connect } from "react-redux";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { STRS_ENV, FG_ID, EMBEDDED_URL, STRS_API_URL } from '../../constants/global';
class ProfilePage extends Component {
  session;
  script =
    "var mtcaptchaConfig = {'sitekey': 'MTPublic-nQ5yaXtpk'};(function(){var mt_service = document.createElement('script');mt_service.async = true;mt_service.src = 'https://service.mtcaptcha.com/mtcv1/client/mtcaptcha.min.js';(document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(mt_service);var mt_service2 = document.createElement('script');mt_service2.async = true;mt_service2.src = 'https://service2.mtcaptcha.com/mtcv1/client/mtcaptcha2.min.js';(document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(mt_service2);}) ();";
  constructor(props, context) {
    super(props, context);
    this.state = {
      client: this.context.sdkClient,
      confirmPasswordError: undefined,
      confirmEmailError: undefined,
      nissError: undefined,
      countryCode: "+32",
      userAge: undefined,
      showPassword: false,
      showConfirmPassword: false,
      showModal: false,
      modalError: false,
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.langSaved) {
      this.props.changeLang(this.props.langSaved);
    }
    if (typeof window !== "undefined") {
      if (localStorage.getItem("SeatersEmbeddedAuthToken")) {
        const parsedSession = JSON.parse(
          localStorage.getItem("SeatersEmbeddedAuthToken")
        );
        const session = {
          expirationDate: parsedSession.expiresOn,
          token: parsedSession.token,
        };
        if (this.isSessionValid(parsedSession)) {
          this.context.sdkClient.sessionService.setSession(session);
          this.context.sdkClient.fanService.fan().then(fan => {
            if (fan.personalInfo.contactPhoneNumber === null) {
              fan.personalInfo.contactPhoneNumber = {
                countryCallingCode: "32",
                localNumber: undefined,
              };
            }
            this.setState({
              fan,
              idNumber: fan.personalInfo.idNumber,
              firstName: fan.firstName,
              lastName: fan.lastName,
              mail: fan.personalInfo.contactEmail,
              phone: fan.personalInfo.contactPhoneNumber.localNumber,
            });
          });
        }
      }

      this.context.sdkClient.fanService
        .customProfile(
          FG_ID
        )
        .then(res => {
          const smallsvaccinationmanufactureragreement = this.getCustomField(
            res,
            "smallsvaccinationmanufactureragreement"
          );

          const smallsvaccinationcodestatus = this.getCustomField(
            res,
            "smallsvaccinationcodestatus"
          );

          const smallsvaccinationbirthdate = this.getCustomField(
            res,
            "smallsvaccinationbirthdate"
          );

          // Compute user age
          const calculateAge = birthDate => {
            var diff_ms = Date.now() - new Date(birthDate).getTime();
            var age_dt = new Date(diff_ms);
            const userAge = Math.abs(age_dt.getUTCFullYear() - 1970);
            this.setState({ userAge });
            return userAge;
          };

          if (smallsvaccinationbirthdate) {
            const age = calculateAge(smallsvaccinationbirthdate.value);
            if (smallsvaccinationbirthdate.value) {
              this.setState({ showJnjAgreement: age >= 18 && age < 41 });
            }
          }

          if (
            smallsvaccinationmanufactureragreement &&
            smallsvaccinationmanufactureragreement.value
          ) {
            this.setState({ jnjAgreemeentSet: true });
          }
          if (
            smallsvaccinationcodestatus &&
            smallsvaccinationcodestatus.value === "DEACTIVATED"
          ) {
            this.setState({ accountDeactivated: true });
          }
        });
    }
  }

  render() {
    return this.state.fan ? (
      <div className="auth_page">
        {this.state.showModal && (
          <div className="modal">
            <div className="modal_content">
              <h1>
                <Translation trl="delete_account_title" />
              </h1>
              <p>
                <Translation trl="delete_account_text" />
              </p>
              {this.state.modalError && (
                <span className="error">
                  {/* You are currently registered on one or more reserve lists.
                  Please unsubscribe from all reserve lists to be able to delete
                  your account */}
                  <Translation trl="currently_registered" />
                </span>
              )}
              <div className="actions">
                <button
                  className={"primary"}
                  onClick={e =>
                    this.setState({ showModal: false, modalError: false })
                  }
                >
                  <Translation trl="delete_account_cancel" />
                </button>
                <button
                  className={"secondary"}
                  onClick={e => this.onDeleteRequest(e)}
                >
                  <Translation trl="delete_account_ok" />
                </button>
              </div>
            </div>
          </div>
        )}

        {/* JNJ modal */}
        {this.state.showModalJNJ && (
          <div className="modal">
            <div className="modal_content">
              <p>
                <Translation trl="modal_jnj_text1" />
                <strong>
                  <Translation trl="modal_jnj_text2" />
                </strong>
                <br />
              </p>
              {!this.state.jnjAgreemeentSet && (
                <object
                  data={"../../jnj_text_" + this.props.lang + ".pdf#view=FitH"}
                  type="application/pdf"
                >
                  <embed
                    src={"../../jnj_text_" + this.props.lang + ".pdf"}
                    type="application/pdf"
                  />
                </object>
              )}
              {!this.state.jnjAgreemeentSet && (
                <p>
                  <Translation trl="more_infos_jnj_1" />
                  &nbsp;
                  <a
                    target="_blank"
                    href={"../../jnj_pdf_" + this.props.lang + ".pdf"}
                  >
                    <Translation trl="more_infos_jnj_2" />
                  </a>
                  &nbsp;
                  {this.props.lang === "nl" && (
                    <>
                      <Translation trl="more_infos_jnj_3" />
                      &nbsp;
                      <a
                        target="_blank"
                        href="https://www.laatjevaccineren.be/via-qvax-inschrijven-voor-een-janssen-vaccin"
                      >
                        <Translation trl="more_infos_jnj_4" />
                      </a>
                    </>
                  )}
                </p>
              )}
              {!this.state.jnjAgreemeentSet && (
                <div className="checkbox">
                  <Checkbox
                    onChange={e =>
                      this.setState({ optinJnjChecked: e.target.checked })
                    }
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                  <p>
                    <Translation trl="jnj_checkbox" />
                  </p>
                </div>
              )}
              <div className="actions jnj">
                <button
                  className={"primary"}
                  onClick={e =>
                    this.setState({ showModalJNJ: false, modalError: false })
                  }
                >
                  <Translation trl="delete_account_cancel" />
                </button>
                <button
                  className={
                    !this.state.jnjAgreemeentSet && !this.state.optinJnjChecked
                      ? "disabled"
                      : "secondary"
                  }
                  onClick={e => this.onJnjSubmit()}
                >
                  {!this.state.jnjAgreemeentSet && (
                    <Translation trl="jnj_modal_ok" />
                  )}
                  {this.state.jnjAgreemeentSet && (
                    <Translation trl="jnj_modal_nok" />
                  )}
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Reactivation modal */}
        {this.state.showModalReactivate && (
          <div className="modal">
            <div className="modal_content">
              <p>
                <Translation trl="reactivate_modal_1" />
                <br />
                <Translation trl="reactivate_modal_2" />
                &nbsp;
                <a href="www.myhealthviewer.be">
                  <Translation trl="reactivate_modal_link" />
                </a>
                <br />
              </p>
              <div className="actions jnj">
                <button
                  className={"primary"}
                  onClick={e =>
                    this.setState({
                      showModalReactivate: false,
                      showReactivateError: false,
                      modalError: false,
                    })
                  }
                >
                  <Translation trl="delete_account_cancel" />
                </button>
                <button
                  className={"secondary"}
                  onClick={e => this.onReactivate()}
                >
                  <Translation trl="reactivate_ok" />
                </button>
                {this.state.showReactivateError && (
                  <Translation trl="reactivate_error" />
                )}
              </div>
            </div>
          </div>
        )}

        <div className="titles">
          <span
            className="back"
            onClick={() => navigate("/", { state: { lang: this.props.lang } })}
          >
            <ArrowBackIosIcon />
            <Translation trl="back_list" />
          </span>
          <span
            className="back_mobile"
            onClick={() => navigate("/", { state: { lang: this.props.lang } })}
          >
            <ArrowBackIosIcon />
            <Translation trl="back" />
          </span>
          <h1>
            <Translation trl="profile_title" />
          </h1>
        </div>
        {/* <div className="vaccin_toggles">
          {this.state.showJnjAgreement && (
            <div className={"jnj_toggle"}>
              <span>
                <Translation trl="jnj_button" />
              </span>
              <div
                onClick={e => this.setState({ showModalJNJ: true })}
                className={this.state.jnjAgreemeentSet ? "accepted" : "refused"}
              >
                <Translation
                  trl={
                    this.state.jnjAgreemeentSet
                      ? "jnj_button_toggle_accepted"
                      : "jnj_button_toggle_refused"
                  }
                />
                <EditIcon id="pen_button" fontSize="inherit" />
              </div>
            </div>
          )}
        </div> */}
{/*
        Reactivate button : not available for 12-15 yrs
        {this.state.userAge > 15 && (
          <div className="vaccin_toggles">
            {/* {this.state.showJnjAgreement && ( 
            <div className={"jnj_toggle"} id="reactivate_button">
              <span>
                <Translation trl="reactivate_button" />
              </span>
              <div
                onClick={e =>
                  this.setState({
                    showModalReactivate: this.state.accountDeactivated,
                  })
                }
                className={
                  this.state.accountDeactivated ? "refused" : "accepted rctvt"
                }
              >
                <Translation
                  trl={
                    this.state.accountDeactivated
                      ? "reactivate_toggle"
                      : "reactivate_activated"
                  }
                />
                {this.state.accountDeactivated && (
                  <EditIcon id="pen_button" fontSize="inherit" />
                )}
              </div>
            </div>
             )} 
          </div>
        )}*/}

        {/* Profile form */}
        <form>
          <div
            className={
              this.state.mailError
                ? "form_input error"
                : this.state.mailError === false
                ? "form_input success"
                : "form_input"
            }
          >
            <label>
              <Translation trl="niss" />
              <CheckCircleOutlineRoundedIcon />
            </label>
            <input
              value={
                this.state.idNumber
                  ? this.formatNiss(this.state.fan.personalInfo.idNumber)
                  : ""
              }
              disabled
            />
          </div>
          {/* <div className={this.state.pwdError ? 'form_input half error' : this.state.pwdError === false ? 'form_input half success' : 'form_input half'}>
							<label><div><Translation trl="pwd"/>&nbsp;(<span className={this.state.pwdMinLengthError ? 'pwd_error' : ''}><Translation trl="pwd_min_length"/></span>, <span className={this.state.pwdAlphaNumError ? 'pwd_error' : ''}><Translation trl="pwd_alpha"/></span>, <span className={this.state.pwdMixCaseError ? 'pwd_error' : ''}><Translation trl="pwd_mix_case"/></span> <span className={this.state.pwdSpecialCharError ? 'pwd_error' : ''}><Translation trl="pwd_special"/></span>)</div><CheckCircleOutlineRoundedIcon/></label>
							<input type={this.state.showPassword ? 'text' : 'password'} autoComplete="new-password" placeholder="**********" value={this.state.password} onChange={(e) => this.handlePasswordChange(e.target.value)} onBlur={(e) => this.checkPassword(e.target.value)}/>
							{!this.state.showPassword && (<VisibilityIcon className="pwd_icon" onClick={() => this.setState({showPassword: true})}/>)}
							{this.state.showPassword && (<VisibilityOffIcon className="pwd_icon" onClick={() => this.setState({showPassword: false})}/>)}
						</div>
						<div className={this.state.confirmPasswordError ? 'form_input half error' : this.state.confirmPasswordError === false ? 'form_input half success' : 'form_input half'}>
							<label><Translation trl="conf_pwd"/><CheckCircleOutlineRoundedIcon/></label>
							<input type={this.state.showConfirmPassword ? 'text' : 'password'} autoComplete="new-password" placeholder="**********" value={this.state.confirmPassword} onChange={(e) => this.setState({confirmPassword: e.target.value})} onBlur={(e)=> this.checkConfirmPassword(e.target.value)}/>
							{!this.state.showConfirmPassword && (<VisibilityIcon className="pwd_icon" onClick={() => this.setState({showConfirmPassword: true})}/>)}
							{this.state.showConfirmPassword && (<VisibilityOffIcon className="pwd_icon" onClick={() => this.setState({showConfirmPassword: false})}/>)}
						</div> */}
          <div className="form_input half">
            <label>
              <Translation trl="firstname" />
            </label>
            <input
              placeholder="John"
              value={this.state.fan.firstName}
              onChange={e => {
                const fan = this.state.fan;
                fan.firstName = e.target.value;
                this.setState({ fan, updated: false });
              }}
            />
          </div>
          <div className="form_input half">
            <label>
              <Translation trl="lastname" />
            </label>
            <input
              placeholder="Smith"
              value={this.state.fan.lastName}
              onChange={e => {
                const fan = this.state.fan;
                fan.lastName = e.target.value;
                this.setState({ fan, updated: false });
              }}
            />
          </div>

          <div className="form_input half">
            <label>
              <Translation trl="mail" />
              <CheckCircleOutlineRoundedIcon />
            </label>
            <input
              autoComplete="username"
              placeholder="john.smith@mail.com"
              value={this.state.fan.personalInfo.contactEmail}
              onChange={e => {
                const fan = this.state.fan;
                fan.personalInfo.contactEmail = e.target.value;
                this.setState({ fan, updated: false });
              }} /*  onBlur={(e) => this.checkMail(e.target.value)} */
            />
          </div>

          <div className={"form_input half"}>
            <label>
              <Translation trl="phone" />
            </label>
            <div className="phone">
              <input type="text" value="+32" disabled />
              <input
                type="text"
                value={this.state.phone}
                onChange={e =>
                  this.setState({ updated: false, phone: e.target.value })
                }
              />
            </div>
          </div>
          {/* <div className={'form_input radio-jnj half'}>
							<label>Available for JnJ</label>
							<RadioGroup row aria-label="position" name="position" onChange={(e) => this.changeJnjValue(e.target.value)}>
								<FormControlLabel
									value="true"
									control={<Radio color="primary" />}
									label="Yes"
									labelPlacement="start"
								/>
								<FormControlLabel
									value="false"
									control={<Radio color="primary" />}
									label="No"
									labelPlacement="start"
								/>
							</RadioGroup>
						</div> */}
        </form>

        {/* Errors */}
        {this.state.signupErrors && (
          <div className="errors">
            {this.state.signupErrors.map(error => (
              <span>
                <Translation trl={error} />
              </span>
            ))}
          </div>
        )}

        {/* Validation */}
        <div className="actions">
          <button className={"primary"} onClick={e => this.onSubmit(e)}>
            {!this.state.loading && !this.state.updated && (
              <span>
                <Translation trl="update_profile" />
              </span>
            )}
            {this.state.loading && (
              <span>
                <CircularProgress size={25} />
              </span>
            )}
            {this.state.updated && (
              <span>
                <CheckIcon />
              </span>
            )}
          </button>
          <button
            className={"secondary"}
            onClick={e => this.setState({ showModal: true })}
          >
            {!this.state.loading && !this.state.updated && (
              <span>
                <Translation trl="deleteAccount" />
              </span>
            )}
            {this.state.loading && (
              <span>
                <CircularProgress size={25} />
              </span>
            )}
            {this.state.updated && (
              <span>
                <CheckIcon />
              </span>
            )}
          </button>
        </div>
      </div>
    ) : (
      <div className="auth_page">
        <div className="titles">
          <span
            className="back"
            onClick={() => navigate("/", { state: { lang: this.props.lang } })}
          >
            <ArrowBackIosIcon />
            <Translation trl="back_list" />
          </span>
          <span
            className="back_mobile"
            onClick={() => navigate("/", { state: { lang: this.props.lang } })}
          >
            <ArrowBackIosIcon />
            <Translation trl="back" />
          </span>
          <h1>
            <Translation trl="profile_title" />
          </h1>
        </div>
      </div>
    );
  }

  onDocumentLoadSuccess(res) {
    this.setState({ numPages: res._pdfInfo.numPages });
  }

  onReactivate() {
    return this.state.client.fanService
      .reactivateDoclrUser(
        FG_ID,
        null
      )
      .then(res => {
        if (!res.codeReactivated) {
          this.setState({ showReactivateError: true });
        } else {
          navigate("/", { state: { lang: this.props.lang } });
        }
      });
  }

  changeJnjValue(value) {
    this.setState({ jnjValue: value });
  }

  onJnjSubmit() {
    localStorage.setItem("qvax-jnj-agreement", btoa(this.state.idNumber));
    if (!this.state.jnjAgreemeentSet) {
      return this.state.client.fanService
        .consentVaccinationSchema(
          FG_ID,
          null
        )
        .then(() => {
          this.setState({
            showModalJNJ: false,
            jnjAgreemeentSet: true,
            optinJnjChecked: false,
          });
        });
    } else {
      return this.state.client.fanService
        .deleteConsentVaccinationSchema(
          FG_ID,
          null
        )
        .then(() => {
          this.setState({
            showModalJNJ: false,
            jnjAgreemeentSet: false,
            optinJnjChecked: false,
          });
        });
    }
  }

  getCustomField(customInfos, key) {
    for (let info of customInfos) {
      if (info.field.key === key) {
        return info;
      }
    }
    return null;
  }

  onSubmit(event) {
    event.preventDefault();
    const fan = this.state.fan;
    if (this.state.phone) {
      fan.personalInfo.contactPhoneNumber = {
        countryCallingCode: "32",
        localNumber: this.state.phone,
      };
    } else {
      fan.personalInfo.contactPhoneNumber = null;
    }
    this.setState({ loading: true });
    this.state.client.fanService
      .updateFan(fan)
      .then(res => {
        if (this.state.jnjValue) {
          console.log("JNJ =>", this.state.jnjValue);
        }
        return Promise.resolve(res);
      })
      .then(res => {
        this.setState({ loading: false, updated: true });
      })
      .catch(error => {});
  }

  onDeleteRequest(event) {
    event.preventDefault();
    this.state.client.fanService
      .requestDelete(FG_ID)
      .then(res => {
        if (typeof window !== "undefined") {
          localStorage.removeItem("qvax-session");
          localStorage.removeItem("SeatersEmbeddedAuthToken");
          navigate("/login", { state: { lang: this.props.lang } });
        }
      })
      .catch(error => this.setState({ modalError: true }));
  }

  checkMail(value) {
    if (value.length > 0) {
      const mail = value;
      const mailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      const isMatching = mailPattern.test(mail);
      this.setState({ mailError: !isMatching && mail.length > 0 });
    } else {
      this.setState({ mailError: undefined });
    }
  }

  isSessionValid(session) {
    const expireDate = new Date(session.expiresOn);
    const dateNow = new Date();
    return expireDate > dateNow;
  }

  isClientSide() {
    return typeof window !== "undefined";
  }

  formatNiss(value) {
    const numbers = value.replace(/\D/g, "");
    const numb = numbers.split("");
    let inserted = 0;
    numbers.split("").forEach((n, i) => {
      if (i === 2 || i === 4 || i === 9) {
        numb.splice(i + inserted, 0, ".");
        inserted += 1;
      }
      if (i === 6) {
        numb.splice(i + inserted, 0, "-");
        inserted += 1;
      }
    });
    return numb.join("");
  }
}

ProfilePage.contextType = MyContext;
const mapStateToProps = state => ({
  login: state.login,
  lang: state.lang,
});

const mapDispatchToProps = () => {
  return {
    logIn,
    changeLang,
  };
};

export default connect(mapStateToProps, mapDispatchToProps())(ProfilePage);
