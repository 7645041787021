import React, { Component } from "react"
import ContextProvider from "../components/context/contextProvider"
import { createStore } from "redux"
import { Provider } from "react-redux"
import allReducer from "../reducers"
import "../styles/shared.scss"
import Cookies from "../components/cookies/cookies";
import MessageAlert from "../components/message-alert/message-alert";
import ProfilePage from "../components/profile-page/profile-page"
import Navbar from "../components/navbar/Navbar"
import Footer from "../components/footer/footer"
import { navigate } from "gatsby"

let store
if (typeof window !== "undefined") {
  store = createStore(
    allReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
} else {
  store = createStore(allReducer)
}

class Profile extends Component {
  constructor(props) {
    super(props)
    if (typeof window !== "undefined") {
      const session = localStorage.getItem("SeatersEmbeddedAuthToken")
      if (!session) {
        if (!localStorage.getItem("qvax-selected-region")) {
          navigate("/region")
        } else {
          navigate("/register")
        }
      } else {
        const parsedSession = JSON.parse(session)
        if (!this.isSessionValid(parsedSession)) {
          navigate("/login")
        }
      }
    }
  }

  render() {
    return (
      <ContextProvider>
        <Provider store={store}>
          <Navbar logout={true} />
          <Cookies />
          <ProfilePage
            langSaved={
              this.props.location.state ? this.props.location.state.lang : null
            }
          />
          <Footer />
        </Provider>
      </ContextProvider>
    )
  }

  isSessionValid(session) {
    const expireDate = new Date(session.expiresOn)
    const dateNow = new Date()
    return expireDate > dateNow
  }
}
export default Profile
